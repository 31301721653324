<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background: #f6f6f6;
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
  min-height: 570px;
  background: #f6f6f6;

  // overflow: auto;
}

@font-face {
  font-family: "Sitka";
  src: url("./assets/fonts/Sitka.ttc");
}

.message-box {
  padding: 10px 10px 20px 10px !important;
}

.message-button_confirm {
  background-color: #344768 !important;
  border-color: #344768 !important;
}

.message-button_cancel:hover {
  color: #344768 !important;
  border-color: #b4bac0 !important;
  background-color: #e0e2e3 !important;
}
</style>
