import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/accounts/login'
        },
        {
            path: '/accounts',
            component: () => import('../views/accounts/index.vue'),
            children: [
                {
                    path: "/accounts/login",
                    component: () => import('../views/accounts/components/userLogin.vue')
                },
                {
                    path: "/accounts/signup",
                    component: () => import('../views/accounts/components/userRegister.vue')
                },
                {
                    path: "/accounts/forget",
                    component: () => import('../views/accounts/components/forgetPassword.vue')
                }
            ]
        },
        {
            path: '/show',
            component: () => import('../views/showPlatform.vue'),
            redirect: '/show/showreward',
            children: [
                {
                    path: '/show/showpersonal',
                    name: 'showpersonal',
                    component: () => import('../views/showPersonal/index.vue'),
                },
                {
                    path: '/show/showpersonnel',
                    name: 'showpersonnel',
                    component: () => import('../views/showPersonnel/index.vue'),
                },
                {
                    path: '/show/showproject',
                    name: 'showproject',
                    component: () => import('../views/showProject/index.vue')
                },
                {
                    path: '/show/showreward',
                    name: 'showreward',
                    component: () => import('../views/showReward/index.vue'),
                   
                }
            ]
        },
        {
            path: '/show/showpersonnel/persondetail/:id',
            component: () => import('../views/showPersonnel/components/PersonDetail.vue')
        },
        {
            path: '/show/showpersonnel/createperson',
            component: () => import('../views/showPersonnel/components/CreatePerson.vue')
        },
        {
            path: '/show/showproject/projectdetail/:id',
            component: () => import('../views/showProject/ProjectDetail.vue')
        },
        {
            path: '/show/showpersonal/edit',
            component: () => import('../views/showPersonal/components/EditPerson.vue')
        }
    ]
});

export default router;
