import Vue from 'vue';
import App from './App.vue';
import router from './router';

import axios from 'axios';

import ElementUI from 'element-ui';
//点击遮罩层不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.MessageBox.setDefaults({
    closeOnClickModal: false
  })
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import SvgIcon from './components/SvgIcon/SvgIcon.vue'
import './assets/iconfont/iconfont.js';
import './assets/iconfont/iconfont.css';
Vue.component('svg-icon', SvgIcon)

Vue.config.productionTip = false;
import store from './store/index';
new Vue({
    render: (h) => h(App),
    store,
    axios,
    router,
    SvgIcon
}).$mount('#app');
